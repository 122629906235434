// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "d0_HW";
export var authorHead__aboutAuthorTitle = "d0_HV";
export var authorHead__aboutAuthorWrapper = "d0_HT";
export var authorHead__description = "d0_HR";
export var authorHead__flexWrapper = "d0_HM";
export var authorHead__name = "d0_HP";
export var authorHead__photo = "d0_HN";
export var authorHead__position = "d0_HQ";
export var authorHead__socialWrapper = "d0_HS";
export var authorHead__wrapper = "d0_HL";